.topButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 210px;
  height: 56px;
  padding: 10px 16px;
  background-color: var(--color-light-20);
  border: 1px solid var(--color-light-10);
  backdrop-filter: blur(10px);
  border-radius: 12px;
}

.blue {
  background: var(--gradient-1);
  border: none;
}
.green {
  background: var(--gradient-green);
  border: none;
}

.cyan {
  background: var(--gradient-2);
  color: white;
  border: none;
}

.label {
  width: 100px;
  text-align: left;
}
.blue .label,
.green .label {
  color: var(--color-white);
}

.b,
.k {
  display: inline-block;
  margin-left: 4px;
  transform: translate(0%, +10%);
}

.img {
  position: absolute;
  width: 44px;
  height: 44px;
  bottom: -4px;
  right: 20px;
  z-index: -1;
}

.arrow {
  position: absolute;
  margin-left: 6px;
  top: 3px;
}

.iconContainer {
  position: relative;
  width: 36px;
  height: 36px;
}

@media (max-width: 1000px) {
  .topButton {
    min-width: 100%;
  }
  .img {
    right: 45px;
  }
}
