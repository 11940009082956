.container {
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-template-rows: 70px 1fr; /* 70px - размер должен совпадать */
  gap: 0px 16px;
  grid-auto-flow: row;
  grid-template-areas:
    "header header"
    "main right";
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 0 24px;
  margin: 0 auto;
}

.header {
  grid-area: header;
  height: 70px; /* 70px - размер должен совпадать */
}

.main {
  grid-area: main;
}

.right {
  grid-area: right;
  /* max-width: 300px; */
}

@media (max-width: 1000px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 8px;
    padding-bottom: 16px;
    grid-template-areas:
      "header"
      "main";
    width: 100%;
    height: 100%;
  }

  .header {
    grid-area: header;
    height: 70px;
  }

  .main {
    grid-area: main;
  }

  .right {
    display: none;
  }
}

@media (max-width: 1000px) {
  .container {
    padding: 0;
  }
}
