.paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 16px;
  background-color: var(--color-white);
}

@media (max-width: 1000px) {
  .paper {
    padding: 16px;
  }
}
