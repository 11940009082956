.previewReadOnly {
  margin-top: 16px;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 0 4px;
  max-width: fit-content;
}

.previewReadOnly > div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
  flex-direction: column;
  padding: 8px;
}

.previewReadOnly > div > img {
  width: 60px;
}
