.stoneExample {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.stoneExample > div {
  padding: 8px;
  text-align: center;
}

.preview {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1;
}
