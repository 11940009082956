.container {
  background: var(--color-white);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingStar {
  display: flex;
}

.workerName {
  color: var(--color-text);
}

.review {
  margin: 16px 0 0 0;
}

.textGrey {
  color: var(--color-light-40);
}

.reviewContent {
  margin: 2px 0 0 0;
}

.priceButton {
  width: 100%;
  color: var(--color-white);
  background: var(--gradient-1);
  margin: 16px 0 0 0;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
}
