.colors {
  display: flex;
  gap: 10px;
}

.colors > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.colors .check {
  width: 16px;
  height: 12px;
}

.white {
  background: #ffffff;
  border: solid 1px #d2d2d2;
}

.gray {
  background: #848484;
}

.black {
  background: #212121;
}

.beige {
  background: #faf0be;
}

.orange {
  background: #cda055;
}

.brown {
  background: #5c3317;
}

.colors > .allColors {
  width: 66px;
  height: 36px;
}
