.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 16px;
  grid-template-areas: ". . . .";
  margin-bottom: 24px;
}

@media (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px 16px;
    grid-template-areas:
      ". ."
      ". .";
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
