.orderBlock {
  position: sticky;
  left: 0;
  top: 20px;
  border-radius: 16px;
  background: #161616;
  margin-bottom: 24px;
  min-width: 300px;
}

.orderList {
  padding: 24px;
  color: var(--color-white);
}

.orderList > h4 {
  color: var(--color-white);
  margin-top: 20px;
}

.orderList > ul {
  list-style-image: url('../../../assets/common/check.svg');
  margin-left: 18px;
}

.orderList > ul > li {
  margin-top: 14px;
  color: var(--color-white);
  opacity: 0.7;
}

.orderList > ul > li > ul > li {
  list-style-type: none;
}

.buttonCalc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background: var(--gradient-1);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--color-white);
}

.buttonEditBlock {
  margin: 0 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.buttonEdit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background: var(--color-light-0);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--color-white);
}

.iconEdit {
  margin-right: 5px;
}

@media (max-width: 1000px) {
  .orderList {
    margin-left: 16px;
    margin-right: 16px;
  }
  .orderBlock {
    position: static;
    margin: 0 16px 62px;
  }
  .orderBlockHandlers {
    margin-bottom: 16px;
  }
}

@media (max-width: 480px) {
  .orderBlock {
    position: relative;
    margin-bottom: 48px;
  }
  .orderBlockHandlers {
    margin-bottom: 16px;
  }
}

.ldsRing {
  top: -10px;
  display: flex;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 4px;
  transform: translateX(-10px);
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.addButton {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 26px;
  right: 0px;
  background: var(--gradient-1);
  line-height: 30px;
  width: 30px;
  height: 30px;
  outline-style: none;
  border-radius: 20px;
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  z-index: 1;
}
