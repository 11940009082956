.typeSelector {
  display: flex;
  align-items: center;
}

.button {
  position: relative;
  width: 120px;
  height: 36px;
  border: solid 1px var(--color-light-12);
  border-left: 0px;
  border-right: 0px;
  background-color: var(--color-white);
}

.active {
  color: var(--color-white);
  background: var(--gradient-1);
}

.button:first-child {
  border-left: solid 1px var(--color-light-12);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.button:last-child {
  border-right: solid 1px var(--color-light-12);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
