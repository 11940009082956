.close {
  position: absolute;
  right: -14px;
  top: -14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: black;
  border-radius: 50%;
  cursor: pointer;
}

.close img {
  width: 10px;
}
