.selectBlock {
  position: absolute;
  transform: translate(-50%, -50%);
}

.countertop-empty {
  top: 26.49%;
  left: 63%;
}

.countertop-selected {
  top: 28%;
  left: 60%;
}

.island-empty {
  top: 41.5%;
  left: 63%;
}

.island-selected {
  top: 43%;
  left: 60%;
}

.button {
  background: var(--color-light-0);
  text-align: left;
  max-width: 90px;
}

.selectTitle {
  position: absolute;
  top: 13px;
  left: 15px;
  width: 80px;
  text-align: left;
}

.selectedTitleActive {
  color: var(--color-text);
}

.selectBlockOpen {
  background: var(--gradient-1);
}

.selectBlockClose {
  background: var(--color-grey-90);
}
.selectBlockClose:hover {
  background: var(--gradient-select-hover);
}

.option {
  /* display: flex; */
  /* flex-wrap: wrap; */
  position: relative;
  /* align-items: center; */
  /* height: 80px; */
  padding: 0 12px 0 12px;
  /* color: var(--color-black); */
  /* background-color: var(--color-white); */
  cursor: pointer;
}

.optionLine {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  /* flex-wrap: wrap; */
  position: relative;
  align-items: center;
  height: 44px;
  padding: 0 30px 0 12px;
  color: var(--color-black);
  background-color: var(--color-white);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.scrollContainer {
  position: absolute;
  max-height: 350px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  width: 224px;
  background-color: var(--color-white);
  overflow-y: scroll;
  scrollbar-width: none;
}

.scrollContainer::-webkit-scrollbar {
  width: 0;
}

.options {
  display: flex;
  width: 224px;

  flex-direction: column;
  /* overflow-y: scroll; */
  /* max-height: 350px; */
  border-radius: 12px;
  text-align: left;
  /* scrollbar-width: none; */
}
/* 
.options::-webkit-scrollbar {
  width: 0;
} */

.option:first-child {
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
  background: var(--color-white);
}

.option:last-child > .optionLine {
  /* border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; */
  /* height: 44px; */
  /* padding-left: 20px; */
  /* background: var(--color-white); */
  border: none;
}

/* .option:first-child:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 79px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 0.08;
  border: none;
} */

.option:last-child:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 1;
  opacity: 0.08;
  border: none;
}

.option:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 43px;
  border: none;
  background: var(--gradient-1);
  opacity: 0.08;
}

.img {
  height: fit-content;
  width: fit-content;
  padding-right: 16px;
  /* padding-left: 8px; */
}

/* .line {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 200px;
} */

.selectedTitle {
  color: var(--color-selected-option);
}

.arrow {
  margin-left: 3px;
  display: inline;
}

.titleContainer {
  /* display: flex; */
}

.title {
  display: inline;
}

.washingImg {
  height: 7vw;
  max-height: 94px;
}

@media (max-width: 1000px) {
  .selectBlock {
    position: static;
    transform: none;
  }
  .empty {
    top: 0;
    left: 0;
  }
  .selected {
    top: 0;
    left: 0;
  }
  .titleContainer {
    /* display: flex; */
    justify-content: space-between;
    width: 100%;
  }
  .button {
    width: 100%;
  }
  .scrollContainer {
    display: none;
    /* position: fixed; */
    /* width: 100%; */
    /* left: 0; */
    /* right: 0; */
    /* top: 10%; */
    /* height: 100%; */
    /* overflow: hidden; */
    border-radius: 0px;
  }
  .options {
    width: 100%;
  }
  .selectTitle {
    position: static;
    text-align: left;
    width: 100%;
  }
  .washingImg {
    display: none;
  }
  .line {
    width: 100%;
  }
  .titleContainer {
    display: flex;
  }
  .button {
    max-width: 100%;
  }
}
