.closeButton {
  display: flex;
  align-items: center;
  position: absolute;
  width: 20px;
  height: 20px;
  outline-style: none;
  border-radius: 15px;
  background-color: var(--color-black);
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  line-height: 20px;
  z-index: 10;
}

.number {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -17px;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 9;
}

.closeIcon {
  margin-right: auto;
  margin-left: auto;
}

.closeButton:hover {
  background-color: rgb(126, 22, 22);
}

.closeButton:active {
  background-color: rgb(210, 16, 16);
}

.left {
  top: 8%;
  left: 9.5%;
}

.front {
  top: 2%;
  left: 84%;
}

.right {
  top: 80%;
  left: 99%;
}

.leftBottom {
  top: 95%;
  left: 28%;
}

.rightBottom {
  top: 95%;
  left: 72%;
}

.addButton {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: var(--gradient-1);
  line-height: 40px;
  width: 40px;
  height: 40px;
  outline-style: none;
  border-radius: 20px;
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  z-index: 10;
}

@media (max-width: 1000px) {
  .addButton {
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    /* box-shadow: 0px 0px 20px rgb(242, 0, 255); */
  }
}

.right.Q {
  top: 33%;
  left: 93%;
}

.right.L {
  top: 33%;
  left: 93%;
}
