.mobileOptions {
  display: none;
}

.mobileOptionMenu {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 12px;
}

.mobilePanelsMenu {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 8px;
}

.panelSizeItem {
  display: flex;
  flex-direction: column;
  height: 50px;
  min-width: calc(50% - 8px);
  max-width: calc(50% - 8px);
  margin-bottom: 16px;
}

.panelInputs {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 36px;
  color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.panelInputs div {
  display: flex;
  justify-content: space-between;
  height: 36px;
  width: 50%;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 18px;
}

.panelInputs span {
  align-self: center;
}

.panelTitle {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: black;
  padding-bottom: 6px;
}

.title {
  padding-bottom: 6px;
}

.panel {
  padding: 10px 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 36px;
}

.panel:last-child {
  border-right: none;
}

.panelHeight {
  border-radius: 12px 12px 0 0;
  border-bottom: solid 1px #3f3f3f;
}

.panelWidth {
  border-radius: 0 0 12px 12px;
}

.panelSizes input {
  background: #2c2c2c;
  color: white;
  max-width: calc(100% - 16px);
  outline: none;
  border: none;
}

@media (max-width: 1000px) {
  .mobileOptions {
    display: grid;
    grid-template-columns: minmax(0%, 1fr) minmax(0%, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-gap: 17px;
    z-index: 2;
    margin-bottom: 16px;
  }

  .countertop {
    grid-template-areas:
      '. .'
      'hob hob'
      'panels panels';
  }

  .island {
    grid-template-areas:
      '. hob'
      'height  thickness';
  }

  .hob {
    grid-area: hob;
  }

  .panels {
    grid-area: panels;
  }
  .height {
    grid-area: height;
  }
  .islandHeight {
    border-radius: 10px;
    padding: 10px 12px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    min-height: 36px;
  }
}
