.container {
  background-color: rgba(0, 0, 255, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 50px;
}

.wrap {
  border-radius: 12px 12px 0 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background-color: var(--color-white);
}

.contentWrap {
  position: relative;
  max-height: 90vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.contentWrap::-webkit-scrollbar {
  width: 0;
}

.head {
  border-radius: 12px 12px 0 0;
  height: 42px;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  padding: 16px 17px 0px 16px;
  position: fixed;
  z-index: 10;
  width: 100%;
}

.cross {
  font-size: 17px;
  cursor: pointer;
}

.buffer {
  height: 40px;
}
