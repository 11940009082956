.orderForm {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.buttonPost {
  color: white;
  height: 56px;
  background: linear-gradient(90deg, #3b41d6 0%, #6b3bd6 100%);
  border-radius: 12px;
}

.citiesList {
  display: flex;
  flex-direction: column;
  position: absolute;
  box-shadow: 5px 3px 3px rgba(0, 0, 0, 0.15);
  top: 350px;
}

.citiesList div {
  padding: 16px;
  background-color: rgb(255, 255, 255);
}

.select {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border: solid 1px #cecece;
  border-radius: 10px;
  height: 36px;
  padding: 0px 12px;
  margin-top: 6px;
  margin-bottom: 14px;
  color: black;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 10px;
  top: 16px;
  right: 10px;
  position: absolute;
}

.ldsRing {
  top: -10px;
  display: flex;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 4px;
  left: 40%;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
