.line {
  position: absolute;
  border-radius: 6px;
  height: 1%;
}

.edge {
  background: var(--gradient-1);
}

.plinth {
  background: var(--gradient-green);
}

/* rgba(59, 65, 214, 1) 0%,
rgba(107, 59, 214, 1) 100% */

.dotted {
  background-image: linear-gradient(
    45deg,
    #3b41d6 25%,
    #ffffff 25%,
    #ffffff 50%,
    #3b41d6 50%,
    #3b41d6 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-size: 28.28px 28.28px;
  opacity: 0.5;
}

.c-U-innerLeft {
  left: 14%;
  top: 61.3%;
  width: 30.5%;
  transform: rotate(96deg);
}

.c-U-outerLeft {
  left: -7%;
  top: 51.9%;
  width: 42%;
  transform: rotate(100deg);
}

.c-U-innerRight {
  right: 14.2%;
  top: 60.9%;
  width: 30%;
  transform: rotate(-96.5deg);
}

.c-U-outerRight {
  right: -6.6%;
  top: 51.9%;
  width: 42.1%;
  transform: rotate(-100.5deg);
}

.c-U-innerMiddle {
  left: 30.5%;
  top: 36.9%;
  width: 38.9%;
}

.c-U-outerMiddle {
  left: 17.7%;
  top: 18.5%;
  width: 64%;
}

.c-U-outerLeftBottom {
  left: 10.1%;
  top: 85.7%;
  width: 17.6%;
}

.c-U-outerRightBottom {
  left: 72.2%;
  top: 85.7%;
  width: 17.5%;
}

/* Прямая столешница */

.c-Q-outerLeft {
  left: 11%;
  top: 27.7%;
  width: 11.5%;
  transform: rotate(101deg);
}

.c-Q-outerRight {
  right: 11.5%;
  top: 27.5%;
  width: 11.6%;
  transform: rotate(258deg);
}

.c-Q-outerMiddle {
  left: 17.7%;
  top: 18.5%;
  width: 64%;
}

.c-Q-outerBottom {
  left: 15.7%;
  top: 36.5%;
  width: 68.5%;
}

/* Г-образная */
.c-L-innerLeft {
  left: 14%;
  top: 61.3%;
  width: 30.5%;
  transform: rotate(96deg);
}

.c-L-outerLeft {
  left: -7%;
  top: 51.9%;
  width: 42%;
  transform: rotate(100deg);
}

.c-L-innerRight {
  right: 14.2%;
  top: 60.9%;
  width: 30%;
  transform: rotate(-96.5deg);
}

.c-L-outerRight {
  right: 11.7%;
  top: 27.5%;
  width: 11.5%;
  transform: rotate(-100.2deg);
}

.c-L-innerMiddle {
  left: 30.5%;
  top: 36.9%;
  width: 53.5%;
}

.c-L-outerMiddle {
  left: 17.7%;
  top: 18.5%;
  width: 64%;
}

.c-L-outerLeftBottom {
  left: 10.1%;
  top: 85.7%;
  width: 17.6%;
}

/* Г-образная */
.c-R-innerLeft {
  left: 14%;
  top: 61.3%;
  width: 30.5%;
  transform: rotate(96deg);
}

.c-R-outerLeft {
  left: 11%;
  top: 27.9%;
  width: 12%;
  transform: rotate(101deg);
}

.c-R-innerMiddle {
  left: 15.9%;
  top: 36.9%;
  width: 53.5%;
}

.c-R-outerMiddle {
  left: 18%;
  top: 18.5%;
  width: 64.8%;
}

.c-R-outerRight {
  right: -7%;
  top: 51.9%;
  width: 42.1%;
  transform: rotate(-100deg);
}

.c-R-innerRight {
  right: 14.2%;
  top: 60.9%;
  width: 30%;
  transform: rotate(-96.5deg);
}

.c-R-outerRightBottom {
  left: 72.2%;
  top: 85.7%;
  width: 17.5%;
}
