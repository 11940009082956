.prices {
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background: var(--color-bg);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}

.container {
  max-width: 1280px;
  margin: 0px auto;
}

.title {
  margin: 20px 0 20px 40px;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient-1);
  font-size: 20px;
  line-height: 28px;
  color: white;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 30px;
}
