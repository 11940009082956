.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  /* height: 140px; */
  padding: 20px 24px;
  background-color: var(--color-white);
  margin-bottom: 16px;
  border-radius: 16px;
}

.left {
  display: flex;
}

.photoBlock {
  min-width: 100px;
  max-width: 100px;
  height: fit-content;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  margin-right: 24px;
}

.photo {
  width: 100%;
}

.name {
  margin-bottom: 12px;
}

.textGrey {
  color: var(--color-light-40);
}

.reviewContent {
  padding: 2px 0 12px 0;
}

.reviewsContainer {
  height: 100%;
}

.otherReviews {
  cursor: pointer;
  color: var(--color-blue);
  margin-top: auto;
}

.right {
  flex: 1;
  display: flex;
}

.rating {
  margin-right: 32px;
}

.ratingLabel {
  text-align: right;
}

.ratingStar {
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
}

.days {
  margin-top: 2px;
  text-align: left;
}

.desc {
  font-size: 15px;
  padding: 8px 0;
}

.priceButton {
  max-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
  gap: 8px;
  padding-top: 18px;
}
.priceButton p {
  font-size: 12px;
}

.price {
  width: 160px;
  height: 56px;
  border-radius: 12px;
  color: var(--color-white);
  background: var(--gradient-1);
}

.price:disabled {
  filter: opacity(0.5);
  cursor: wait;
}

.firstLine {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.line {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }

  .left {
    align-items: flex-start;
  }

  .right {
    margin-left: 24px;
    align-items: flex-end;
    flex-direction: column;
  }

  .rating {
    margin: 0;
  }

  .photoBlock {
    margin: 0;
  }

  .priceButton {
    align-self: center;
    margin: 0;
  }

  .price {
    height: 44px;
  }
}

div {
  white-space: pre-wrap;
}
