.e {
  position: absolute;
  background: var(--gradient-1);
  border-radius: 6px;
  height: 1%;
}

.g {
  background: var(--gradient-cian);
}

.edge1 {
  left: 27.3%;
  top: 31.3%;
  width: 45.5%;
}

.edge2 {
  left: 20%;
  top: 43.2%;
  width: 14%;
  transform: rotate(95deg);
}

.edge3 {
  left: 26.3%;
  top: 54.9%;
  width: 47.5%;
}

.edge4 {
  left: 66%;
  top: 43.2%;
  width: 14%;
  transform: rotate(85.5deg);
}
