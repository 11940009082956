.inputBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  max-width: 90%;
  border: 0;
}

.input:focus {
  outline: none;
}

.cm {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  opacity: 0.4;
}

.inputBlockError {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputBlockError input {
  color: var(--color-red);
}

.error {
  position: absolute;
  width: fit-content;
  padding: 12px;
  margin: 10px 0 0 -12px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: var(--color-red);
  background-color: var(--color-white);
}
