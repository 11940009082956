.panelSizes {
  position: absolute;
  padding: 8px;
  color: #ffffff;
  z-index: 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.panelSizes > div {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 7px;
  width: 70px;
  background: #2b2b2b;
  padding: 7px;
}

.label {
  user-select: none;
  color: #7d7d7d;
}

.panelHeight {
  border-radius: 12px 12px 0 0;
  border-bottom: solid 1px #3f3f3f;
}

.panelWidth {
  border-radius: 0 0 12px 12px;
}

.panelSizes input {
  background: #2c2c2c;
  color: white;
  max-width: calc(100% - 16px);
  outline: none;
  border: none;
}

.front {
  top: -2%;
  right: 5%;
}

.left {
  top: 4%;
  left: -2%;
}

.leftBottom {
  top: 83%;
  left: 29%;
}

.right {
  top: 82%;
  right: -1%;
}

.rightBottom {
  top: 83%;
  right: 29%;
}

.right.Q {
  top: 34.5%;
  right: 5%;
}

.right.L {
  top: 34.5%;
  right: 5%;
}

.inputSize {
  background: none;
}

.inputSize input {
  max-width: 43px;
  font-size: 14px;
  background: none;
}

.error {
  position: absolute;
  width: fit-content;
  padding: 12px;
  margin-top: 4px;
  border-radius: 10px;
  color: var(--color-red);
  background-color: var(--color-white);
  z-index: 100;
}

.inputBlockError input {
  color: red;
}
