.bar {
  display: flex;
  position: relative;
  max-width: 842px;
  height: auto;
  margin: 0 auto;
}

.header {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}

.tooltip {
  position: relative;
  display: inline-block;
  top: 39.5%;
  left: 71.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-1);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.tooltip .tooltiptext {
  top: 30px;
  left: calc(-50% - 5px);
  visibility: hidden;
  width: 90px;
  background-color: black !important;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 113;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
