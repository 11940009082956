.mobileMenu {
  position: absolute;
  width: 140px;
  height: 132px;
  background-color: white;
  border-radius: 12px;

  -webkit-box-shadow: 4px -4px 100px 100px rgba(34, 60, 80, 0.23);
  -moz-box-shadow: 4px -4px 100px 100px rgba(34, 60, 80, 0.23);
  box-shadow: 4px -4px 100px 100px rgba(34, 60, 80, 0.23);
  overflow: hidden;
}

.right {
  left: -145px;
}

.left {
  left: 35px;
}

.none {
  display: none;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: black;
  height: 44px;
}

.delete {
  color: brown;
}

.edge {
  color: var(--color-white);
  background: linear-gradient(90deg, #3b41d6 0%, #6b3bd6 100%);
}

.plinth {
  color: var(--color-white);
  background: linear-gradient(90deg, #008e7d 0%, #01cd77 100%);
}
