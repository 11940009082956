.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px 0px;
  grid-auto-flow: row;
  grid-template-areas: "brands";
  border-bottom: 0.5px solid var(--color-light-10);
  /* gap: 0px 8px; */
  padding-bottom: 16px;
}
.brandsList {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 1fr;
  grid-area: brands;
  padding-top: 1px;
  gap: 0px 8px;
}

.brandBlock {
  margin-bottom: 24px;
  cursor: pointer;
  box-sizing: content-box;
}

.ok {
  position: absolute;
  right: 8px;
  top: 8px;
}

.imgBlock {
  position: relative;
  padding: 2px 10px;
  margin-bottom: 8px;
  border: 3px solid var(--color-white);
  outline: 0.5px solid var(--color-light-12);
  border-radius: 12px;
}

.brandImg {
  width: 100%;
}

.selected {
  border: 3px solid var(--color-blue);
}

.moreBtn {
  display: none;
  background-color: f2f2f2;
  border-radius: 10px;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  grid-area: moreBtn;
}

@media (max-width: 1000px) {
  .container {
    grid-template-areas:
      "brands"
      "moreBtn";
  }
  .brandsList {
    grid-template-columns: repeat(3, 1fr);
  }

  .moreBtn {
    display: block;
  }
}
