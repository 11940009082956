.island {
  display: flex;
  position: relative;
  max-width: 842px;
  height: auto;
  margin: 0 auto;
}

.header {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}
