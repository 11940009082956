.optionsItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;
  padding: 16px 20px 20px 20px;
  border-radius: 16px;
  background: var(--gradient-opacity-option);
  cursor: pointer;
  overflow-wrap: anywhere;
}

.background {
  background-position: right;
  background-size: cover;
  border-radius: 16px;
  background-repeat: no-repeat;
}

.active {
  background: var(--gradient-opacity);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.h4 {
  padding: 0 0 8px 0;
}

.active .h4 {
  color: var(--color-white);
}

.optionsItem p {
  margin: 0px;
}

.optionsItem.active p {
  color: var(--color-white);
}

.controls {
  display: flex;
  align-items: flex-end;
}

@media (max-width: 1000px) {
  .optionsItem {
    height: 240px;
    padding: 16px;
  }
}
