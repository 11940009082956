.inputSize {
  position: absolute;
  transform: translate(-50%, -50%);
  width: min-content;
  z-index: 1;
}

.container {
  position: relative;
}

.inputBlock {
  display: flex;
  width: max-content;
  height: 36px;
  padding: 0px 7px;
  margin-top: 5px;
  border: 2px solid rgb(45, 45, 45);
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  background: var(--color-grey-90);
  box-shadow: 0px -5px 50px var(--color-light-12);
}

.inputBlockError {
  display: flex;
  width: max-content;
  height: 36px;
  padding: 0px 7px;
  margin-top: 5px;
  border: 2px solid var(--color-red);
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  background-color: var(--color-red);
  box-shadow: 0px -5px 50px var(--color-light-12);
}

.inputBlock:hover {
  background: var(--gradient-select-hover);
  border: 2px solid rgb(76, 76, 76);
}

.inputBlockFocus,
.inputBlockFocus:hover {
  background-color: var(--color-white);
  border-color: var(--color-blue);
}

.input {
  max-width: 100%;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--color-white);
  background-color: var(--color-light-0);
}

.input:focus {
  cursor: text;
  color: var(--color-grey-90);
  background-color: var(--color-white);
}

.input::placeholder {
  color: var(--color-white);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cm {
  width: 18px;
  margin: 10px 6px 0px 0px;
  color: var(--color-white);
  opacity: 0.5;
}

.error {
  position: absolute;
  width: fit-content;
  padding: 12px;
  margin-top: 4px;
  border-radius: 10px;
  color: var(--color-red);
  background-color: var(--color-white);
  z-index: 200;
}

@media (max-width: 1000px) {
  .inputBlock {
    height: 30px;
    border-radius: 8px;
    padding: 2px 2px;
    height: 30px;
  }
}

@media all and (max-width: 1000px) {
  .InputSize input {
    height: 20px;
  }
}
