.subhead {
  display: flex;
  justify-content: left;
  align-items: center;
  /* height: 50px; */
  padding-left: 30px;
  padding-bottom: 16px;
}

@media (max-width: 1000px) {
  .subhead {
    padding-left: 16px;
  }
}
