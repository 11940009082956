.iswitch {
  z-index: 2;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: black;
  background-color: white;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
}

.iswitch > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
}

.iswitch > :nth-child(1) {
  color: white;
  background: var(--gradient-1);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
}

.iswitch > :nth-child(2) {
  color: black;
  background: white;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.iswitch.active > :nth-child(2) {
  color: white;
  background: var(--gradient-cian);
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  top: 30px;
  left: calc(-50% - 5px);
  visibility: hidden;
  width: 90px;
  background-color: black !important;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 113;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.edge1 {
  top: 32%;
  left: 50%;
}

.edge2 {
  top: 42%;
  left: 27%;
}

.edge3 {
  top: 56%;
  left: 50%;
}

.edge4 {
  top: 42%;
  left: 73%;
}
