.countertop {
  display: flex;
  position: relative;
  max-width: 842px;
  height: auto;
  margin: 0 auto;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 16px;
}

.infoCardOverlay {
  position: absolute;
  top: 73%; /* Отрегулируйте, чтобы расположить текст в нужном месте */
  left: 50%; /* Отрегулируйте по горизонтали */
  transform: translate(-50%, -50%);
  max-width: 35%;
  z-index: 0; /* Убедитесь, что это выше изображения */
}
