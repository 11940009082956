.loginBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gradient-1);
  padding: 16px;
}

.workerInfo {
  display: flex;
  align-items: center;
  gap: 24px;
  color: white;
}

.loginForm {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.loginBar button {
  border: solid 1px #333;
  border-radius: 4px;
  padding: 8px;
  background: var(--gradient-green);
  color: white;
}

.loginBar input {
  border: solid 1px #333;
  padding: 4px;
  border-radius: 4px;
  width: 120px;
}
