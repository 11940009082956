.productItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  padding: 16px 20px 20px 20px;
  border-radius: 16px;
  background: var(--gradient-opacity-product);
  cursor: pointer;
}

.background {
  border-radius: 16px;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.header {
  height: 20px;
  margin: 0 0 8px 0;
}

.text {
  color: var(--color-black);
  width: 200px;
}

.active {
  background: var(--gradient-opacity-product-active);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.active .header,
.active .text {
  color: var(--color-white);
}

@media (max-width: 1000px) {
  .productItem {
    flex-grow: 1;
    height: 180px;
    padding: 16px;
  }
}
