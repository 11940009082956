.modalOverlay {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.modal {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 344px;
  min-height: 100px;
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
}

.modalContent {
  flex: 1;
}

.modalClose {
  cursor: pointer;
  font-weight: 600;
  font-size: 21px;
  line-height: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.modalClose:hover {
  border-radius: 12px;
  background-color: #efefef;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -22px;
}

@media (max-width: 1000px) {
  .modalOverlay {
    position: fixed;
    background-color: rgb(0, 0, 0, 0.8);
    z-index: 10;
  }

  .modal {
    position: fixed;
    z-index: 100;
  }

  .closeIcon {
    display: none;
  }
}
