.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 36px;
  width: 130px;
}

.toggleMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 24px;
  width: 130px;
}

.toggleMobileImg {
  height: 24px;
}
