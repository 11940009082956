.showMain {
  position: absolute;
  display: flex;
  align-items: center;
  width: 120px;
  gap: 8px;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  cursor: pointer;
}
