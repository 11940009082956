.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: none;
}

.button:disabled {
  color: var(--color-black);
}

.active,
.active:disabled {
  color: var(--color-white);
  background: var(--gradient-1);
}

.left {
  margin-right: 24px;
}

.right {
  margin-left: 24px;
}

.num {
  margin-top: 1px;
}
