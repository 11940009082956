.comments {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 12px;
  gap: 8px;
}

.commentEditBox {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px 12px;
  gap: 16px;
}

.textarea {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  outline: none;
  width: 100%;
  padding: 12px;
  padding-right: 24px;
  border-radius: 10px;
  overflow: hidden;
  resize: none;
}

.taBlack {
  border: 1px solid #323232;
  background: #000000;
  color: #ffffff;
  padding: 12px;
}

.taGrey {
  border: 1px solid #323232;
  background: #323232;
  color: #ffffff;
  padding: 12px;
}

.taWhite {
  border: 1px solid #323232;
  border-radius: 10px;
}

:focus::placeholder {
  color: transparent;
}

.delete {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  z-index: 30;
}

.commentBox {
  position: relative;
  background: #2b2b2f;
  border-radius: 10px;
  padding: 12px;
  padding-right: 48px;
}

.commentBoxText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
  color: #ffffff;
}

.commentBoxPrice {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 22px;
  /* identical to box height, or 105% */

  letter-spacing: -0.03em;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(90deg, #3b41d6 0%, #6b3bd6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
