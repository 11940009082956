.inputSize {
  position: absolute;
  transform: translate(-50%, 0%);
  width: min-content;
}

.number {
  margin-right: 6px;
  background-color: var(--color-light-0);
}

.input:focus {
  color: var(--color-black);
  background-color: var(--color-white);
  cursor: text;
}

.input::placeholder {
  color: var(--color-white);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* .cm {
  left: 12px;
  width: 18px;
  margin-top: 4px;
  color: var(--color-white);
  opacity: 0.5;
} */

.selectBlock {
  display: flex;
  align-items: center;
  width: 60px;
  height: 38px;
  padding: 2px 10px;
  border-radius: 10px;
  color: var(--color-white);
}

.selectBlockOpen {
  background: var(--gradient-1);
}

.selectBlockClose {
  background: var(--color-grey-90);
}
.selectBlockClose:hover {
  background: var(--gradient-select-hover);
}

.option {
  display: flex;
  position: relative;
  width: 60px;
  height: 44px;
  color: var(--color-black);
  background-color: var(--color-white);
}

.optionNumber {
  margin: 14px 0 14px 16px;
}

.options {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin-top: 4px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
}

.option:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--color-white);
}
.option:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--color-white);
}

.option:first-child:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 0.08;
}

.option:last-child:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 0.08;
}

.option:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-1);
  opacity: 0.08;
}

.inputBlockFocus {
  border-color: var(--color-blue);
  background-color: var(--color-white);
}

.arrow {
  width: 10px;
  margin-left: 8px;
}

@media all and (max-width: 600px) {
  .InputSize input {
    height: 30px;
  }
}

.length1 {
  top: 13.5%;
  left: 50%;
}

.topRight {
  top: 16%;
  left: 92%;
}

.width1 {
  top: 45%;
  left: 15%;
}

.thickness {
  top: 50%;
  left: 50%;
}

.island {
  top: 13%;
  left: 79%;
}

.thicknessU {
  top: 50%;
  left: 50%;
}

.length2 {
  top: 94%;
  left: 20%;
}

.length3 {
  top: 94%;
  left: 80%;
}

@media (max-width: 1000px) {
  .inputSize {
    position: relative;
    transform: none;
    width: 100%;
  }
  .thickness {
    top: 0;
    left: 0;
  }
  .thicknessU {
    top: 0;
    left: 0;
  }
  .selectBlockClose {
    background: none;
  }
  .selectBlock {
    color: var(--color-black);
    height: auto;
    width: auto;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .number {
    margin-right: 0px;
  }
  .cm {
    margin: 0;
    color: var(--color-black);
    left: 0;
    width: auto;
  }
  .arrow {
    margin-left: 12px;
    width: auto;
  }
  .selectBlockClose:hover {
    background: none;
  }
  .selectBlockOpen {
    background: none;
  }
  .option:last-child:hover::after {
    border-radius: 0px;
  }
  .option {
    width: 100%;
  }
  .options {
    width: 100%;
  }
}
