.edgeSwitch {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
}

.edgeSwitch button {
  width: 40px;
  height: 20px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.plinthSwitch {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
}

.plinthSwitchMobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background: var(--gradient-green);
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.edgeSwitchMobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background: var(--gradient-1);
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.plinthSwitch button {
  width: 40px;
  height: 20px;
  line-height: 20px;
  color: white;
  cursor: pointer;
}

.edgeSwitch button:nth-child(1) {
  background-color: var(--color-blue);
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
}

.edgeSwitch button:nth-child(2) {
  background-color: white;
  color: black;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.plinthSwitch button:nth-child(1) {
  color: black;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
}

.plinthSwitch button:nth-child(2) {
  background: var(--gradient-green);
  color: white;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
}

.addButton {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: var(--gradient-1);
  line-height: 40px;
  width: 40px;
  height: 40px;
  outline-style: none;
  border-radius: 20px;
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media (max-width: 1000px) {
  .addButton {
    line-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  top: 30px;
  left: calc(-50% - 5px);
  visibility: hidden;
  width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 113;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.Q.edge1 {
  top: 19%;
  left: 25%;
}

.Q.edge2 {
  top: 27%;
  left: 16%;
}

.Q.edge3 {
  top: 36.6%;
  left: 40%;
}

.Q.edge4 {
  top: 27%;
  left: 83%;
}

.L.edge1 {
  top: 19%;
  left: 25%;
}

.L.edge2 {
  top: 28%;
  left: 83%;
}

.L.edge3 {
  top: 86%;
  left: 19.5%;
}

.L.edge4 {
  top: 30%;
  left: 16%;
}

.L.edge5 {
  top: 37%;
  left: 40%;
}

.L.edge6 {
  top: 62%;
  left: 29%;
}

.R.edge1 {
  top: 19%;
  left: 25%;
}

.R.edge2 {
  top: 30%;
  left: 16%;
}

.R.edge3 {
  top: 86%;
  left: 80.5%;
}

.R.edge4 {
  top: 28%;
  left: 83%;
}

.R.edge5 {
  top: 37%;
  left: 40%;
}

.R.edge6 {
  top: 62%;
  left: 71%;
}

.U.edge1 {
  top: 19%;
  left: 25%;
}

.U.edge2 {
  top: 30%;
  left: 16%;
}

.U.edge3 {
  top: 86%;
  left: 19.5%;
}

.U.edge4 {
  top: 62%;
  left: 29%;
}

.U.edge5 {
  top: 37%;
  left: 40%;
}

.U.edge6 {
  top: 60%;
  left: 71%;
}

.U.edge7 {
  top: 86%;
  left: 81%;
}

.U.edge8 {
  top: 28%;
  left: 83%;
}
