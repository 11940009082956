.subtitle {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  /* padding-left: 30px; */
}

@media (max-width: 1000px) {
  .subtitle {
    /* padding-left: 16px; */
  }
}
