.buttonDelete {
  display: flex;
  align-items: center;
  border: solid 1px #e5e5e5;
  padding: 6px 8px;
  border-radius: 8px;
}

.buttonDelete:active {
  display: flex;
  align-items: center;
  border: solid 1px #e5e5e5;
  background-color: #eaeaea;
  padding: 6px 8px;
  border-radius: 8px;
}

.buttonDelete img {
  margin-right: 4px;
}
