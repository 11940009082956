.selectBlock {
  /* z-index: 1; */
}

.selectBlockOpen {
  background: var(--gradient-1);
}

.selectBlockClose {
  background: var(--color-grey-90);
}
.selectBlockClose:hover {
  background: var(--gradient-select-hover);
}

.option {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  height: 80px;
  padding: 10px 0 16px 20px;
  color: var(--color-black);
  background-color: var(--color-white);
  cursor: pointer;
}

.scrollContainer {
  position: absolute;
  height: 440px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  z-index: 10;
}

.options {
  display: flex;
  position: absolute;
  width: 296px;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 440px;
  border-radius: 12px;
  text-align: left;
}

.option:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--color-white);
}
.option:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--color-white);
}

.option:first-child:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 77px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 0.08;
  border: none;
}

.option:last-child:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: var(--gradient-1);
  opacity: 0.08;
  border: none;
}

.option:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 77px;
  border: none;
  background: var(--gradient-1);
  opacity: 0.08;
}

.img {
  height: fit-content;
  width: fit-content;
  padding-right: 16px;
}

.line {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 252px;
}

.selectedTitle {
  color: var(--color-selected-option);
}

.description {
  font-size: 12px;
  font-weight: normal;
  color: #999999;
}

@media all and (max-width: 600px) {
}
