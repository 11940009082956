.input {
  max-width: 100%;
  text-align: right;
  border: none;
  background-color: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  height: 34px;
}

.input:focus {
  max-width: 100%;
  cursor: text;
}

.input::placeholder {
  color: var(--color-white);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.inputDigitalError {
  color: red;
}
