.stonesList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.stoneBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 25%;
  max-width: 25%;
  height: auto;
  padding: 16px;
  cursor: pointer;
}

.imgBlock {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 10px;
}

.circle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 1px #eee;
}

.ok {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
  .stonesList > div {
    flex: 50%;
    max-width: 50%;
  }
}
