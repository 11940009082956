.kp {
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 100%;
  min-height: 100%;
}

.left {
  position: relative;
  display: flex;
  flex-direction: column;
}

.right {
  display: flex;
  flex-direction: column;
}

.offer_number {
  text-align: center;
  font-size: 28px;
}

.right > div {
  position: relative;
  margin: 0;
  top: 0;
}

.screen {
  position: absolute;
  width: 100%;
  height: calc(100% - 200px);
  opacity: 0.5;
  z-index: 100;
}

.additionalConditions {
  padding: 8px;
}

.additionalConditions li {
  margin-left: 24px;
  line-height: 30px;
}

.additionalConditionsMobile {
  display: none;
  padding: 8px;
}

.additionalConditionsMobile li {
  margin-left: 24px;
  line-height: 30px;
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--gradient-1);
  z-index: 1000;
}

@media (max-width: 890px) {
  .additionalConditions {
    display: none;
  }

  .additionalConditionsMobile {
    display: block;
  }

  .kp {
    display: flex;
    flex-direction: column;
  }
  .right {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }

  .right > div {
    flex: 1;
  }
}
