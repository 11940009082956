.header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 22px;
  margin-bottom: 16px;
}

.newCommentForm {
  background: white;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px -5px 50px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 16px 12px;
}

.textArea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 12px;
  padding-right: 32px;
  resize: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
}

.label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 6px;
}

.inputPrice {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 12px;
  margin-bottom: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.wrapper {
  position: relative;
}

.cleanButton {
  background: none;
  position: absolute;
  right: 13px;
  top: 12px;
}

.buttonAdd {
  height: 36px;
  color: white;
  background: linear-gradient(90deg, #3b41d6 0%, #6b3bd6 100%);
  border-radius: 10px;
  margin-bottom: 6px;
}

.buttonCancel {
  height: 36px;
  color: black;
  background: #ffffff;
  border-radius: 10px;
}

.buttonCancel:hover {
  background: #eeeeee;
}

.textArea:focus::placeholder {
  color: transparent;
}

.inputPrice:focus::placeholder {
  color: transparent;
}
