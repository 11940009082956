.inputStep {
  margin-left: 12px;
}

.label {
  color: var(--color-white);
}

.number {
  cursor: default;
}

.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  height: 44px;
  border-radius: 12px;
  background-color: var(--color-white);
  margin-top: 5px;
}

.minus {
  height: 100%;
  padding: 0 8px 0 13px;
  border-radius: 12px;
  background-color: inherit;
}

.minus img,
.plus img {
  margin-top: 3px;
}

.plus {
  height: 100%;
  padding: 0 13px 0 8px;
  border-radius: 12px;
  background-color: inherit;
}
