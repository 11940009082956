.countertopOtionsMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-top: 25px;
}

@media (max-width: 1000px) {
  .countertopOtionsMenu {
    flex-direction: column;
  }
}
