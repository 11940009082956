.inputOrder {
  border: solid 1px #cecece;
  border-radius: 10px;
  height: 36px;
  padding: 0px 12px;
  margin-top: 6px;
  margin-bottom: 14px;
  color: black;
}

.inputOrderError {
  border: solid 1px #ae0000;
  background: #ae0000;
  backdrop-filter: blur(40px);
  border-radius: 10px;
  height: 36px;
  padding: 0px 12px;
  margin-top: 6px;
  margin-bottom: 14px;
  color: rgb(255, 255, 255);
}

/* Chrome, Safari, Edge, Opera */
.inputOrder::-webkit-outer-spin-button,
.inputOrder::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
.inputOrderError::-webkit-outer-spin-button,
.inputOrderError::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
