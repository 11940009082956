.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 16px;
  grid-template-areas: ". .";
  margin-bottom: 16px;
}

@media (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 16px 0px;
    grid-template-areas:
      "."
      ".";
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
