.errorMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: white;
  border: solid 3px #F4444E;
  border-radius: 16px;
  padding: 20px;
}

.errorMessageTitle {
  color: #F4444E;
}

.errorMessage pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.errorMessage button {
  border-radius: 16px;
  padding: 10px;
  background: #563ed6;
  color: aliceblue;
  width: 200px;
  margin-top: 2rem;
}
