.footer {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 31px;
}

.logo,
.telegram,
.phoneNumber,
.email {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
  color: #000000;
  text-decoration: none;
  word-wrap: 'nowrap';
}

.logo,
.telegram,
.phoneNumber,
.email a {
  word-wrap: 'nowrap';
}

.privacy a {
  display: flex;
  gap: 17px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  text-decoration: none;

  letter-spacing: -0.02em;

  /* Dark Gray / 02 */

  color: #acacac;
}

.copyrightMessage {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
  color: #acacac;
}

.divider {
  display: block;
  width: 327px;
  opacity: 0.1;
  background: #202124;
  margin-bottom: 16px;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

@media (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 16px 16px;
    grid-template-areas:
      '.'
      '.'
      '.'
      '.';
    margin-bottom: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
}
